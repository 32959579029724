.cardContainer {
    background-color:rgba(44,51,63,255);
    color: white;
    padding-left: 0px;
    padding-right: 0px;
    transition: .5s;
    padding-bottom: 0px;
}

.cardBody {
    padding: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    border: solid;
    border-color: #212529;
}

.cardBody:hover {
    border-color: #758a7f;
    transition: .5s;
}

.cardContainer  .header {
    display: flex;
    position: relative;
}

.header .cardIcon {
    scale: 2;
    margin: 10px;
    cursor: pointer;
}

.header .cardIcon:hover {
    color: #758a7f;
    transition: .2s;
}

.header .name {
    background-color: #758a7f;
    padding: 7px;
    border-style: solid;
    border-radius: 10px;
    max-width: 100%;

}
.cardUpDownIcon {
    scale: 2;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 16px;
}
.cardUpDownIcon:hover {
    color: #758a7f;
    transition: .2s;
}

.location {
    padding: 5px;
    padding-left: 90px;
}

.cardContainer .footer {
    display: flex;
    margin-left: 45px;
}

.footer .cardIcon {
    scale: 2;
    margin: 10px;
    margin-left: 50px;
}

.cardLetter {
    border-style: solid;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    text-align: center;
    margin-right: 40px;
}



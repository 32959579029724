@import url('https://fonts.googleapis.com/css?family=Rubik');

* {
  font-family: 'Rubik', sans-serif;
}

.textblock {
    background-color: #2c333f;
    z-index: 200;
    position: relative;
    height: 400vh;
  }
  
  .textblockContainer {
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .imgContainer {
      background-color: aliceblue;
      border-radius: 20px;
      border-style: solid;
      width: 100%;
      border-color: black;
  }

  .videoContainer {
    background-color: black;
    border-radius: 20px;
    border-style: solid;
    width: 100%;
    aspect-ratio: 16/9;
    border-color: black;
    overflow: hidden;

}

.video {
    width: 100%;
    height: 100%;
}
  

  .leftContainer {
    width: 40%;
    margin-left: 5%;
  }

  .rightContainer {
    width: 40%;
    margin-right: 5%;
  }
  
  .textblockTitle {
    color: #ffffff;
    font-size: 35px;
    font-weight: 600;
    font-family: "Helvetica Neue";
  }
  
  .textblockContent {
    color: #ffffff;
    font-size: 20px;
  }
  

  
  .homePageContainerBuffer {
    overflow: hidden;
  }

  .backgroundImageContainer {
      height: calc(100vh);
      overflow: hidden;
  }


  .nextScrollHome {
      text-align: center;
      position: absolute;
      bottom: 15%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      z-index: 400;
      font-size: 50px;
      text-decoration: underline;
  }

  .nextScrollHome:hover {
    text-shadow: 0 0 11px white; 
}

  .nextScrollSecondary {
    text-align: center;
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 400;
    font-size: 50px;
    text-decoration: underline;
  }

 .nextScrollSecondary:hover {
    text-shadow: 0 0 11px white; 
  }

  .scrollBar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    transform-origin: 0%;
    z-index: 500;
    background-color: #758a7f ;
  }

 .backgroundSunset {
    z-index: 0;
    position: absolute;
    aspect-ratio: 1920/1080;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../media/homepageImgs/SunsetBG1.png');
 }

 .backgroundTitle {
    z-index: 10;
    position: absolute;
    aspect-ratio: 1920/1080;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../media/homepageImgs/TitleBG5.png');
 }

 .backgroundSetting1 {
    z-index: 20;
    position: absolute;
    aspect-ratio: 1920/1080;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../media/homepageImgs/SettingBG3.png');
 }

.background {
    height: calc(100vh - 115px);
    overflow-y: hidden;
    background-color: rgba(44,51,63,255);
    margin-top: 115px;;
}

.wrapper  {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.leftDash {
    width: 50%;
    background-color: #212529;
    overflow-y: scroll;
}

.leftDashBody {
    background-color: #212529;
    border: solid;
    height: 90%;
    overflow-y: scroll;
}

.leftDashBody::-webkit-scrollbar {
    display: none;
  }

.leftDash::-webkit-scrollbar {
    display: none;
}

.filterHeader {
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    background-color: rgb(221, 227, 236);
    height: 100px; 
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    border-style: solid;
    border-right-width: 5px;
    border-left-width: 5px;
    border-color: #212529;
    border-radius: 20px;
}

.searchBar {
    display: flex;
    border-style: solid;
    border-radius: 10px;
    z-index: -2;
    margin: 0px;
    background-color: #9FA6B2;
}

.image {
    scroll-snap-align: start;
}

.rightDash {
    width: 50%;
    background-color: #212529;
}

.rightDashBody {
    background-color:rgba(44,51,63,255);
    height: 95%;
    width: 95%;
    padding-top: 80px;
    border-style: solid;
    border-radius: 30px;
}


input[id="searchID"]
{
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border: 5px solid #292b2c;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    z-index: -1;
}
.popUpContainer {
    background-color: rgba(44,51,63,255);
    position: absolute; 
    left: 90%;
    top: 10%;
    z-index: 10;
    width: 40%;
    height: 80%;
    border-style: solid;
    border-radius: 30px;
}

.popUpHeader {
    color: white;
    text-align: center;
    height: 15%;
    padding-top: 20px;
}

.popUpBody {
    background-color: #758a7f;
    height: 60%;
    overflow-y: scroll;
}

.popUpFooter {
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    padding-top: 50px;
    height: 15%;
}

.popUpFooter input {
    height: 20px;
}

.timeCard {
    border-style: solid;
    border-color: #212529;
    display: flex;
    justify-content: flex-start;
}

.timeCard input {
    height: 20px;
    margin: auto;
}
/* Used in NewTrip.js */

.body {
    height: calc(100vh - 119px);
    display: flex;
    justify-content: center;
    background-image: url('../../media/svgBackgrounds/newtriplayer3.svg');
    justify-content: center;
    margin-top: 119px;
    background-size: cover;
}

.moduleBody {
    display: flex;
    border-radius: 20px;
    border-style: solid;
    border-color: black;
    overflow: hidden;
    margin: 5%;
}

.bodyLeft {
    width: 50%;
    background-color: #758a7f;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bodyRight {
    border-left-style: solid;
    background-color: #212529;
    color: aliceblue;
    border-left-color:black;
    width: 50%;
    overflow-y: auto;
}

.spacer {
    z-index:0;
    position: absolute;
    aspect-ratio: 960/300;
    width: 100%;
    background-repeat: repeat;
    bottom: 0;
    background-size: cover;
    background-image: url('../../media/svgBackgrounds/newtriplayer3.svg');
}
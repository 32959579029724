.body {
    background-color: #758a7f;
    height: calc(100vh - 119px);
    display: flex;
    background-image: url('../../media/svgBackgrounds/newtriplayer3.svg');
    justify-content: center;
    margin-top: 119px;
    background-size: cover;
}

.infoContainer {
    border-style: solid;
    border-color: black;
    border-width: 10px;
    border-radius: 7px;
    color: white;
    background-color: #212529;
    height: 60%;
    width: 70%;
    margin-top: 10%;
    display: flex;
}

.leftInfoContainer {
    width: 70%;
    height: 100%;
    background-color: #212529;
    border-right-style: solid;
    border-right-color: black;
    border-right-width: 10px;
    overflow-y: auto;
}

.row {
    display: flex;
    font-weight: bold;
    padding: 15px;
    justify-content: space-between;
    background-color: #758a7f;
    margin: 10px;
    border-radius: 7px;
    
}

.rowLeft {
    width: 30%;
}

.rowMiddle {
    width: 40%;
}

.rowRight {
    width: 30%;
    text-align: center;
}

.rightInfoContainer {
    width: 30%;
    height: 100%;
    text-align: center;
    position: relative;
    overflow: auto;
}

.logo {
    width: 50%;
    z-index: 100;
    position: absolute;
    top: 30%;
    left: 25%;
    width: 60%;
}
.sparksIcon {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.editIcon {
    cursor: pointer;
    scale: 1.2;
}

.editIcon:hover {
    color: #dd5c66;
}

.infoName {
    font-weight: bold;
    font-size: x-large;
    width: 90%;
    margin:auto;
    padding: 3px;
    background-color: #758a7f;
    border-radius: 7px;
}

span {
    overflow: hidden;
}

.spanOverflow {
    width: 40%;
    overflow-x: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.description::-webkit-scrollbar {
    display: none;
  }

/* Used in TileOverview.js */

.tileOverviewContainer {
    background-color: #212529;;
    border-color: black;
    color: #292b2c;
    font-weight: bold;
    margin: auto;
    text-align: center;
    border-radius: 20px;
    border-style: solid;
    height: 80%;
    width: 75%;
    overflow: hidden;
    color: rgb(221, 227, 236);
    position:relative;
}

.tileImgContainer {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 40%;
    border-radius: 20px;
    border-style: solid;
    overflow: hidden;
    z-index: 0;
    margin-top: 5%;
}

.titleContainer {
    font-size: 230%;
    z-index: 10;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50%;
    color: #758a7f;
}

.descriptionContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #a0aaba;
    min-height: 25%;
    width: 100%;
}

.description {
    font-size: 25px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    max-width: 60%;
    max-height: 100px;
    word-break: break-all;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.description::-webkit-scrollbar {
    display: none;
  }
  
.footerContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    min-height: 25%;
}

.button {
    color: white;
    padding: 10px 20px;
    background-color:#758a7f;
    border: solid;
    border-radius: 7px;
    border-color: black;
    cursor: pointer;
    font-size: 30px;
    transition: all 0.3s ease 0s;
}

.button:hover {
    background-color:#9FA6B2;
}

.titleImgContainer {
    display: flex;
    justify-content: center;
    border-radius: 20px;
    border-style: solid;
    height: 80%;
    width: 75%;
    border-radius: 20px;
    border-style: solid;
    color: black;
    position:relative;
    margin: auto;
    overflow: hidden;
}
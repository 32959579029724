/* Used in TripTile.js */

.tileContainer {
    margin:auto;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    background-color: aliceblue;
    border-radius: 20px;
    border-style: solid;
    height: 300px;
    width: 75%;
    transition: .5s;
    overflow: hidden;
    z-index: 0;
}

.tileContainer:hover {
    border-color:black;
    transform: scale(1.1);
}

.tileTop {
    padding: 25px;
    padding-left: 10px;
    text-align: left;
    background-color: #758a7f;
    font-weight: bold;
    font-size: 45px;
    display: flex;
    justify-content: space-between;
    border-bottom: solid;
    border-color: black;
    height: 130px;
}

.tileTop .header {
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.header::-webkit-scrollbar {
    display: none;
  }

.tileTop .icon {
    border-color: black;
    padding: 5px;
    border: solid;
    min-width: 45px;
}

.tileBottom {
    padding-top: 65px;
    display: flex;
    justify-content: space-evenly;
    font-size: 20px;
    height: 170px;
}
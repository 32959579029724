.navContainer {
    display: flex;
    width: 100%;
    background-color: #212529;
    color: white;
    align-items: baseline;
    justify-content: space-between;
    padding-top: 9px;
    padding-bottom: 10px;
    position: fixed;
    top: 0;
    z-index: 1000;

}

.transparent {
    background-color: transparent;
    display: flex;
    width: 100%;
    color: white;
    align-items: baseline;
    justify-content: space-between;
    padding-top: 9px;
    padding-bottom: 10px;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.navHeader {
    padding-right: 25px;
    padding-top: 3px;
    font-size: xx-large;
    text-decoration: none;
}

.navBody {
    display: flex;
    margin-left: 15%;
}

.navAccount {
    margin-right: 70px;
    display: flex;
}

.link { 
    padding: 10px;
    padding-top: 30px;
    font-size: x-large;
    color: white;
    text-decoration: none;
}

.link:hover {
    padding: 10px;
    padding-top: 30px;
    font-size: x-large;
    color: #758a7f;
    text-decoration: none;
}

.logoContainer {
    margin-right: 50px;
}

.logo {
    width: 100px;
    height: 100px;
}
